@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-sans text-neutral-800;
}

.site-padding {
  @apply p-2 md:p-3;
}

.site-padding-x {
  @apply px-2 md:px-3;
}

.site-margin-x {
  @apply mx-2 md:mx-3;
}

.site-top {
  @apply top-2 md:top-3;
}

.site-gap {
  @apply gap-2 md:gap-3;
}

.rounded-large {
  @apply rounded-xl;
}

.rounded-small {
  @apply rounded-lg;
}

.generic-input {
  @apply rounded-small bg-white bg-opacity-5 outline-none ring-1 ring-white text-white px-4 py-3;
}

.generic-input-dark {
  @apply rounded-small bg-neutral-800 bg-opacity-5 outline-none ring-1 ring-neutral-400 text-neutral-800 px-4 py-3;
}

.button {
  @apply rounded uppercase font-bold tracking-wide py-3 px-5 text-lg font-condensed transition;
}

.button-white {
  @apply bg-white text-neutral-800 hover:bg-cyan-800 hover:text-white;
}

.button-primary {
  @apply bg-cyan-800 text-white hover:bg-neutral-800;
}
